import React from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';
import { formatFloat } from '../utils/formatFloat';
import StarIcon from '@material-ui/icons/Star';

export default function PaymentFrecuency({
	frecuency,
	intervals,
	percent_off,
	selectedInterval,
	handleSelectInterval,
	isoCode
}) {
	const sortedIntervals = ['year', 'semiannual', 'quarterly', 'month'];
	return (
		<div className="w-full">
			<div className="container">
				<div
					className={`w-full flex flex-col mt-10 px-6 sm:grid sm:grid-cols-2 sm:gap-x-4 md:grid-flow-col md:grid-cols-${
						Object.keys(intervals).filter(
							(i) => typeof intervals[i] === 'object'
						).length
					}`}
				>
					{typeof intervals === 'object' &&
						Object.keys(intervals)
							.sort(
								(a, b) =>
									sortedIntervals.indexOf(b) - sortedIntervals.indexOf(a)
							)
							.map((interval, index) => {
								if (typeof intervals[interval] !== 'object') return;
								return (
									<Plan
										key={index}
										selected={
											selectedInterval?.total === intervals[interval]?.total
												? true
												: false
										}
										save={intervals[interval]?.percentage_saving}
										interval={frecuency(interval)}
										cost={formatFloat(intervals[interval]?.monthly_price)}
										total={formatFloat(intervals[interval]?.total)}
										handleClick={() =>
											handleSelectInterval(intervals[interval])
										}
										isoCode={isoCode}
									/>
								);
							})}
				</div>

				{selectedInterval.total && (
					<div className="grid place-items-center gap-y-1 mx-auto mt-12 mb-8 w-full">
						<span className="font-bold text-purple-500 text-lg flex mb-2">
							Total a pagar hoy:
						</span>
						{selectedInterval?.recurrent_interval === 'month' && 
							<span className="text-xl md:text-2xl flex items-center">
							{(selectedInterval?.promotion?.apply_promotion &&
								selectedInterval?.promotion?.percent_off > 0) ||
							percent_off > 0 ? (
								<span className="text-gray-700">
									<s>{formatFloat(2 * selectedInterval?.total)}</s>
								</span>
							) : null}
							<span className="text-center uppercase text-pink-500 font-bold">
								&nbsp; {isoCode.toUpperCase()}{' '}
								{formatFloat(
									2 * selectedInterval?.total -
										(selectedInterval?.total *
											(selectedInterval?.promotion?.percent_off ||
												percent_off)) /
											100
								)}
							</span>
							<span className="text-base text-gray-800">
								/Pago Inicial
							</span>
						</span>
						}
						<span className="text-xl md:text-2xl flex items-center">
							{(selectedInterval?.promotion?.apply_promotion &&
								selectedInterval?.promotion?.percent_off > 0) ||
							percent_off > 0 ? (
								<span className="text-gray-700">
									<s>{formatFloat(selectedInterval?.total)}</s>
								</span>
							) : null}
							<span className="text-center uppercase text-pink-500 font-bold">
								&nbsp; {isoCode.toUpperCase()}{' '}
								{formatFloat(
									selectedInterval?.total -
										(selectedInterval?.total *
											(selectedInterval?.promotion?.percent_off ||
												percent_off)) /
											100
								)}
							</span>
							<span className="text-base text-gray-800">
								/{frecuency(selectedInterval?.recurrent_interval)}
							</span>
						</span>
						{selectedInterval?.percentage_saving <= 0 ? null : (
							<div className="bg-white p-2 rounded-xl border border-purple-300 text-center">
								<span className="text-purple-300 px-12">
									Ahorras{' '}
									<b>
										{isoCode.toUpperCase()}{' '}
										{formatFloat(selectedInterval?.yearly_saving)}
									</b>{' '}
									al año
								</span>
							</div>
						)}
					</div>
				)}
			</div>

			<div className="w-full flex justify-center items-center mt-8 text-sm text-purple-500 leading-relaxed text-center font-bold">
				Por favor tener en cuenta que esta cotización puede variar al momento de
				la afiliación ya que el precio definitivo dependerá de la edad
				actuarial.
			</div>
		</div>
	);
}

function Plan({ selected, save, interval, cost, total, handleClick, isoCode }) {
	const gradient =
		'bg-gradient-to-r from-orange-500 via-orange-600 to-pink-700';
	const parseCost = cost.replace(',','');
	return (
		<div className="grid gap-y-2 place-items-end mt-10 md:mt-0 w-full">
			<FormControlLabel
				className="m-0 w-full"
				name="payment_frecuency"
				control={
					<div
						className={`border border-gray-600 delay-100 duration-100 transition ${
							selected ? gradient : null
						} rounded-md ${
							selected ? 'text-white' : 'text-gray-800'
						} grid place-items-center w-full pb-5`}
						style={{height: 250}}
					>
						<div className="flex justify-end w-full pr-2">
							<Radio
								name={interval}
								className={`${selected ? 'text-white' : 'text-gray-800'}`}
								onClick={handleClick}
								checked={selected}
							/>
						</div>
						<div className="flex items-center">
							<div className={`${selected ? 'text-white border-white' : 'text-gray-600 border-gray-600'} font-bold border p-5 circular-border`}
								style={{borderRadius: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
								<div style={{position: 'absolute', fontSize: 30, height: 38}}>
									{
										interval === 'Anual' ? '1' :
										interval === 'Semestral' ? '2' :
										interval === 'Trimestral' ? '4' :
										'1'
									}
								</div>
							</div>
							{interval === 'Mensual' ? (<div className="font-bold mr-2 ml-2" style={{fontSize: 25}}> + </div>) : null}
							{interval === 'Mensual' ? (
								<div className={`${selected ? 'text-white border-white' : 'text-gray-600 border-gray-600'} font-bold border p-5 circular-border`}
									style={{borderRadius: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
									<div style={{position: 'absolute', fontSize: 30, height: 38}}>
										10
									</div>
								</div>
							) : null}
						</div>
						<span className="font-bold text-lg block text-center">
							{
								interval === 'Anual' ? 'Único pago anual' :
								interval === 'Semestral' ? 'Pagos semestrales' :
								interval === 'Trimestral' ? 'Pagos trimestrales' :
								(<p><u>1 Pago</u> inicial</p>)
							}
						</span>
						{
							interval === 'Mensual' ? (
								<div className="flex flex-col items-center">
									<span
										className={`font-bold sm:text-xl md:text-2xl lg:text-2xl ${
											selected ? 'text-white' : 'text-purple-500'
										}`}
										style={{textAlign: 'center'}}
									>
										{formatFloat((2.0 * (parseCost)))} {isoCode.toUpperCase()}
									</span>
									<span className={`font-bold ${
											selected ? 'text-white' : 'text-purple-500'
										}`}>
									</span>
									<span
										className={`${selected ? 'text-white' : 'text-pink-500'} flex justify-center items-center`}
										style={{fontWeight: 'bold', fontSize: 35, height: 30}}
									>
										+
									</span>
									<span
										className={`font-bold ${
											selected ? 'text-white' : 'text-purple-500'
										}`}
										style={{textAlign: 'center'}}
									>
										<u>10 Pagos</u> mensuales de
									</span>
									<span className={`font-bold sm:text-xl md:text-2xl lg:text-2xl ${
											selected ? 'text-white' : 'text-purple-500'
										}`}
										>
										{cost} {isoCode.toUpperCase()}
									</span>
								</div>
							) : (
								<span
									className={`font-bold sm:text-xl md:text-2xl lg:text-3xl ${
										selected ? 'text-white' : 'text-purple-500'
									}`}
								>
									{total} {isoCode.toUpperCase()}
								</span>
							)
						}
						<div
							className={`py-2 flex items-center justify-center w-full rounded-mdmb-1 place-self-start ${
								save <= 0 ? 'hidden' : 'inline'
							}`}
						>
							<span className={`font-bold text-center border px-3 py-1 ${selected ? 'text-white border-white' : 'text-gray-600 border-gray-600'}`} style={{borderRadius: 5}}>
								Ahorra el {save}%
							</span>
						</div>
					</div>
				}
			/>
			{
				interval === 'Anual' ? (
					<div
						style={{height: 30, textAlign: 'center', fontWeight: 'bold'}}
						className="text-purple-500 text-bold w-full"
					>
						<span>Recomendado</span>
						<StarIcon className="text-pink-500"/>
					</div>
				) : (
					<span style={{height: 30}}>{' '}</span>
				)
			}
		</div>
	);
}
