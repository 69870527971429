export default [
	{
		name: 'Azua',
		value: 'Azua'
	},
	{
		name: 'Barahona',
		value: 'Barahona'
	},
	{
		name: 'Distrito Nacional',
		value: 'Distrito Nacional'
	},
	{
		name: 'Duarte',
		value: 'Duarte'
	},
	{
		name: 'El Seíbo',
		value: 'El Seíbo'
	},
	{
		name: 'Espaillat',
		value: 'Espaillat'
	},
	{
		name: 'Hato Mayor',
		value: 'Hato Mayor'
	},
	{
		name: 'Hermanas Mirabal',
		value: 'Hermanas Mirabal'
	},
	{
		name: 'La Altagracia',
		value: 'La Altagracia'
	},
	{
		name: 'La Romana',
		value: 'La Romana'
	},
	{
		name: 'La Vega',
		value: 'La Vega'
	},
	{
		name: 'María Trinidad Sánchez',
		value: 'María Trinidad Sánchez'
	},
	{
		name: 'Monseñor Nouel',
		value: 'Monseñor Nouel'
	},
	{
		name: 'Peravia',
		value: 'Peravia'
	},
	{
		name: 'Puerto Plata',
		value: 'Puerto Plata'
	},
	{
		name: 'Samaná',
		value: 'Samaná'
	},
	{
		name: 'San Cristóbal',
		value: 'San Cristóbal'
	},
	{
		name: 'San Juan',
		value: 'San Juan'
	},
	{
		name: 'San Pedro de Macorís',
		value: 'San Pedro de Macorís'
	},
	{
		name: 'Sánchez Ramírez',
		value: 'Sánchez Ramírez'
	},
	{
		name: 'Santiago',
		value: 'Santiago'
	},
	{
		name: 'Santo Domingo',
		value: 'Santo Domingo'
	},
	{
		name: 'Valverde',
		value: 'Valverde'
	}
];
